<template>
  <v-col cols="12" md="6" lg="6" offset-lg="3" offset-md="3">
    <v-card class="elevation-1">
      <v-card-title>BIR 2316</v-card-title>
      <v-card-text>
        <v-form>
          <!-- view As -->
          <v-radio-group
            v-model="status"
            row
            class="pt-0 mt-0"
            @change="getEmployees"
          >
            <v-radio
              label="All"
              value="all"
            ></v-radio>
            <v-radio
              label="Active"
              value="active"
            ></v-radio>
            <v-radio
              label="Separated"
              value="separated"
            ></v-radio>
          </v-radio-group>

          <v-text-field
            v-model="year"
            outlined
            dense
            type="number"
            placeholder="YEAR"
            label="YEAR"
            min="2013"
            step="1"
            @keypress.enter="getEmployees"
          ></v-text-field>

          <v-autocomplete
            v-model="employee"
            outlined
            dense
            clearable
            multiple
            label="Employee"
            item-value="id"
            item-text="name"
            :disabled="year==''"
            :loading="employeeLoading"
            :items="employees"
            :class="formErrorFields.employeeErrorMessage.length > 0 ? 'mb-3' : ''"
            :error-messages="formErrorFields.employeeErrorMessage"
            @change="formError().remove('employee', formErrorFields)"
          ></v-autocomplete>

          <v-btn
            color="primary"
            :loading="loading"
            @click="submit">
            Print
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>

    <snackbar
      v-if="message.length > 0"
      :show="message.length > 0"
      :text="message"
      :color="messageStatus"
      @close="message=''"
    ></snackbar>

    <confirm-password
      :show="showConfirmDialog"
      @cancel="showConfirmDialog=false"
      @execute="submit">
    </confirm-password>
  </v-col>
</template>

<script>
import { ref } from '@vue/composition-api'
import get from '@/composables/get'
import crud from '@/composables/crud'
import snackbar from '@/components/SnackBar.vue'
import ConfirmPassword from '@/components/ConfirmPassword.vue'
import formError from '@/composables/formErrorHandler/formError'
import blob from '@/composables/blob'
import fnx from '@/functions/fn'

export default {
  metaInfo: {
    title: 'Payslip eBlast',
  },
  components: {
    snackbar,
    ConfirmPassword,
  },
  setup() {
    const loading = ref(false)
    const year = ref('')
    const status = ref('all')
    const formLoading = ref(false)
    const employee = ref([])
    const employees = ref([])
    const employeeLoading = ref(false)
    const error = ref('')
    const message = ref('')
    const messageStatus = ref('')
    const showConfirmDialog = ref(false)
    const reportData = ref(null)
    const formErrorFields = ref({
      employeeErrorMessage: '',
      yearErrorMessage: '',
    })

    const submit = async () => {
      showConfirmDialog.value = false

      const employeeArray = employee.value.length > 0 ? employee.value : employees.value.map(x=>x.id) 
      for(let emp of employeeArray) {
        
        const empId = emp

        const formData = {
          employee: empId,
          year: year.value,
        }

        const mimetype = 'application/pdf'

        await blob(`${process.env.VUE_APP_URI}/api/payroll/reports/bir-2316/${fnx.base64_encode(formData)}`, reportData, formLoading, error)

        if (error.value.data !== undefined) {
          if (error.value.status === 200) {
            message.value = error.value.statusText
            messageStatus.value = 'success'
          } else {
            message.value = error.value.statusText
            messageStatus.value = 'error'
          }
        } else {
          message.value = ''
          messageStatus.value = ''
          
          const url = window.URL.createObjectURL(new Blob([reportData.value], { type: mimetype }))
          window.open(url, '_blank')
        }
      }

    }

    const getEmployees = () => {
      employees.value = []
      if(year.value == '') {
        get(`${process.env.VUE_APP_URI}/api/payroll/employees/${status.value}`, employees, employeeLoading)
      } else {
        get(`${process.env.VUE_APP_URI}/api/payroll/employees/${status.value}/${year.value}`, employees, employeeLoading)
      }
    }

    getEmployees()

    return {
      submit,
      getEmployees,
      employee,
      employees,
      status,
      employeeLoading,
      loading,
      message,
      messageStatus,
      year,
      showConfirmDialog,
      formError,
      formErrorFields,
    }
  },
}
</script>
